<template>
  <div class="p-12 bg-white">
    <div
      class="text-center m-auto"
      :class="{
        'max-w-6xl': !isCarousel,
        'max-w-wrapper-md': isCarousel
      }"
    >
      <p class="text-lg text-blue-50 font-bold pb-4 sm:pb-6" :class="titleClass">
        As seen on
      </p>

      <div
        v-if="!isCarousel"
        :class="themeClass"
      >
        <div
          v-for="(item, itemKey) in outlets"
          :key="itemKey"
          :class="{'flex shrink max-w-[5rem] xs:max-w-max': theme === 'compact'}"
        >
          <img
            v-bind="item"
            class="m-auto"
          />
        </div>
      </div>

      <swiper-container
        v-else
        ref="swiper"
        slides-per-view="auto"
        init="false"
        class="overflow-hidden lg:pl-15"
        style="--swiper-pagination-bottom: 0"
      >
        <swiper-slide
          v-for="(item, itemKey) in outlets"
          :key="itemKey + '-outlet-carousel'"
          class="exact-wh-[6.375rem] md:exact-w-[7.4285714286rem] md:exact-h-[7.5rem] !inline-flex relative"
        >
          <!-- class="md:exact-w-[160px] lg:exact-w-[180px] relative drop-shadow-sm-2 !inline-flex flex-col items-start swiper-slide-active" -->

          <img
            v-bind="item"
            class="m-auto"
          />
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<script setup>
import { register } from 'swiper/element/bundle'
import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'AtomsMediaOutlets'
})

const props = defineProps({
  mediaOutlets: {
    type: Array,
    required: true
  },

  imageLazyLoad: {
    type: Boolean,
    default: false
  },

  theme: {
    type: String,
    default: 'default'
  },

  isCarousel: {
    type: Boolean,
    default: false
  },

  titleClass: {
    type: String,
    default: ''
  }
})

const outlets = computed(() => {
  const list = props.mediaOutlets
  const lazyLoad = props.imageLazyLoad

  if (_isEmpty(list)) {
    return []
  }

  return list.map(item => {
    const urlArr = item.url.split('/')
    const alt = urlArr[urlArr.length - 1]
      .replace(/.(svg|png|jpg|jpeg|webp|gif)$/g, '')
      .replace(/(-|_)/gm, ' ')

    return {
      ...((item?.url && { src: item.url }) || {}),
      ...((alt && { alt }) || {}),
      ...((item?.width && { width: item.width }) || {}),
      ...((item?.height && { height: item.height }) || {}),
      ...((lazyLoad && { loading: 'lazy' }) || {})
    }
  })
})

const themeClass = computed(() => {
  return getKey(props.theme, {
    default: 'grid lg:grid-cols-8 grid-cols-2 gap-8',
    // compact: 'grid lg:grid-cols-7 sm:grid-cols-5 grid-cols-5 gap-3 xs:gap-4 sm:gap-5 md:gap-8  place-items-center justify-content-center'
    compact: 'flex flex-wrap justify-center gap-4 md:gap-8 md:gap-x-12'
  })
})

if (props.isCarousel) {
  register()
}

const swiper = ref()

const initializeSwiper = () => {
  const el = swiper.value

  if (el) {
    const params = {
      injectStyles: [`
        :host .swiper-pagination {
          height: 8px;
        }

        :host .swiper-pagination-bullet-active {
          background-color: #475D80 !important;
        }

        @media only screen and (max-width: 1023px) {
          :host .swiper-pagination-bullet {
            position: relative;
            top: -9px;
          }

          :host .swiper {
            padding: 0 16px;
            width: calc(100% - 32px);
          }
        }

        @media only screen and (max-width: 767px) {
          :host .swiper {
            height: 136px;
          }
        }

        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          :host .swiper {
            height: 152px;
          }
        }
      `],
      pagination: {
        clickable: true
      },
      spaceBetween: 16
    }

    Object.assign(el, params)
    el.initialize()
  }
}

watch(swiper, (newVal, oldVal) => {
  if (props.isCarousel && newVal !== undefined && oldVal === undefined) {
    initializeSwiper()
  }
})
</script>
